<template>
<div>
  <div>
    <vx-card :title="$i18n.t('Description of Goods')" style="border: 1px solid #ccc;">

      <template slot="actions">
        <vs-button style="margin: 0 auto;" color="primary" size="small" icon-pack="feather" icon="icon-plus" class="mr-4" @click="() => { popup = true }">
          {{ $i18n.t('Add') }}
        </vs-button>
      </template>

      <vs-table :data="detailGoods">

        <template slot="thead">
          <vs-td class="text-left theader">{{$i18n.t('Goods')}}</vs-td>
          <vs-td class="text-right theader">{{$i18n.t('Amount')}}</vs-td>
          <vs-td class="text-right theader">{{$i18n.t('Price')}}</vs-td>
          <vs-td class="text-right theader">{{$i18n.t('Total')}}</vs-td>
          <vs-td class="text-center theader">Action</vs-td>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].name">
              {{ data[indextr].name }}
            </vs-td>

            <vs-td class="text-right" :data="data[indextr].amount">
              {{ data[indextr].amount }}x
            </vs-td>

            <vs-td class="text-right" :data="data[indextr].price">
              {{ $toCurrency(data[indextr].price) }}
            </vs-td>

            <vs-td class="text-right" :data="data[indextr].total">
              {{ $toCurrency(data[indextr].total) }}
            </vs-td>

            <vs-td class="text-center">
              <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer" @click="destroy(indextr)" />
            </vs-td>
          </vs-tr>

          <vs-tr v-if="detailGoods.length > 0">
            <vs-td colspan="3" class="text-center tfooter" style="font-weight: bold;">Net Amount</vs-td>
            <vs-td class="text-right tfooter" style="font-weight: bold;">{{ $toCurrency(subtotal) }}</vs-td>
            <vs-td class="tfooter"></vs-td>
          </vs-tr>

          <vs-tr v-if="detailGoods.length > 0">
            <vs-td colspan="3" class="text-center tfooter" style="font-weight: bold;">VAT</vs-td>
            <vs-td class="text-right tfooter" style="font-weight: bold;">{{ $toCurrency(vat) }}</vs-td>
            <vs-td class="tfooter"></vs-td>
          </vs-tr>

          <vs-tr v-if="detailGoods.length > 0">
            <vs-td colspan="3" class="text-center grand" style="font-weight: bold;">Grand Total</vs-td>
            <vs-td class="text-right grand" style="font-weight: bold;">{{ $toCurrency(grand) }}</vs-td>
            <vs-td class="grand"></vs-td>
          </vs-tr>

        </template>

      </vs-table>

      <vs-popup classContent="popup-example" :title="$i18n.t('Goods')" :active.sync="popup">
        <div class="vx-row mb-6">
          <div class="vx-col md:w-1/1 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('Goods')" v-model="form.name" />
          </div>
          <div class="vx-col md:w-2/12 w-full">
            <vs-input class="w-full mb-3" type="number" min="1" :label="$i18n.t('Amount')" v-model="form.amount" />
          </div>
          <div class="vx-col md:w-5/12 w-full">
            <vs-input class="w-full mb-3" type="number" min="0" :label="$i18n.t('UnitPrice')" v-model="form.price" />
          </div>
          <div class="vx-col md:w-5/12 w-full  mb-3">
            <vs-input class="w-full" readonly :label="$i18n.t('Total')" v-model="form.amount * form.price"/>
            <small>* Automatically</small>
          </div>
        </div>

        <vs-button color="primary" type="filled" @click="add" v-if="!isSelected">Add</vs-button>
        <vs-button color="primary" type="filled" @click="update" v-else>Update</vs-button>
      </vs-popup>

    </vx-card>

  </div>
</div>
</template>

<script>
import momentJS from 'moment'

export default {
  name: "DetailGoods",
  props: ['detailGoods', 'invoiceDate'],
  data: () => ({
    handle: {},
    form: {
      amount: 1,
      price: 0,
    },
    isSelected: false,
    popup: false,
  }),
  computed: {
    subtotal () {
      return this.detailGoods.map(goods => goods.price * goods.amount).reduce((total, item) => total += item);
    },
    vat () {
      let vat = 0.1
      const invoiceDate = momentJS(this.invoiceDate)
      const vatDateChanged = momentJS('2022-04-01')

      if (invoiceDate.isSameOrAfter(vatDateChanged)) {
        vat = 0.11
      }

      return this.subtotal * vat;
    },
    grand () {
      return this.subtotal + this.vat;
    },
  },
  methods: {
    add () {
      this.$emit('add', {
        form: {
          amount: this.form.amount,
          name: this.form.name,
          price: this.form.price,
          total: this.form.price * this.form.amount,
        }
      });

      this.popup = false;
    },
    destroy (index) {
      this.$emit('destroy', {
        index,
      });
    },

  },
  watch: {
    popup() {
      if (!this.popup)
        this.form = {
          amount: 1,
          price: 0,
        };
    }
  }
}
</script>

<style scoped>
.theader {
  font-size: 12px !important;
  font-weight: bold !important;
}
.tfooter {
  font-size: 14px !important;
}
.grand {
  font-size: 16px !important;
  background: lightgrey;
}
</style>
