<template>
  <vx-card>
    <form-wizard color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-danger), 1)" :title="null" :subtitle="null" finishButtonText="Submit">
      <tab-content :title="$i18n.t('InvoiceCustomer')" class="mb-5" icon="feather icon-file-text" :before-change="validateStep1">

        <!-- tab 1 content -->
        <form data-vv-scope="step-1">
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-5">
              <label class="vs-input--label">{{$i18n.t('Date')}}</label>
              <datepicker format="d/MM/yyyy" :value="form.tanggal" @input="form.tanggal = $moment($event)"></datepicker>
              <!--            <vs-input type="date" v-validate="'required'" :label="$i18n.t('Date')" v-model="form.tanggal" name="tanggal" class="w-full" />-->
              <span class="text-danger">{{ errors.first(`step-1.tanggal`) }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
              <label class="vs-input--label">{{$i18n.t('DateIssued')}}</label>
              <datepicker format="d/MM/yyyy" :value="form.date_issued" @input="form.date_issued = $moment($event)"></datepicker>
              <!--            <vs-input type="date" v-validate="'required'" :label="$i18n.t('DateIssued')" v-model="form.date_issued" name="date_issued" class="w-full" />-->
              <span class="text-danger">{{ errors.first(`step-1.date_issued`) }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
              <label class="vs-input--label">{{$i18n.t('DateDue')}}</label>
              <datepicker format="d/MM/yyyy" :value="form.date_due" @input="form.date_due = $moment($event)"></datepicker>
              <!--            <vs-input type="date" v-validate="'required'" :label="$i18n.t('DateDue')" v-model="form.date_due" name="date_due" class="w-full" />-->
              <span class="text-danger">{{ errors.first(`step-1.date_due`) }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
              <label class="vs-input--label">{{$i18n.t('PODate')}}</label>
              <datepicker format="d/MM/yyyy" :value="form.po_date" @input="form.po_date = $moment($event)"></datepicker>
              <!--            <vs-input type="date" v-validate="'required'" :label="$i18n.t('Date')" v-model="form.tanggal" name="tanggal" class="w-full" />-->
              <span class="text-danger">{{ errors.first(`step-1.tanggal`) }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
              <small class="mb-1">{{ $i18n.t('CustomerName') }}</small>
              <v-select :clearable="false" v-model="customerSelected" label="name" :options="customerOptions"></v-select>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
              <vs-input v-validate="'required'" :label="$i18n.t('NPWPNumber')" v-model="form.no_npwp_customer" name="no_npwp_customer" class="w-full" />
              <span class="text-danger">{{ errors.first('step-1.no_npwp_customer') }}</span>
            </div>

            <div class="vx-col md:w-1/1 w-full" v-if="form.customer">

              <!-- Telkomsel Template -->
              <additional :additional="form.additional" class="mt-5" v-on:add="submitAdditional" v-on:destroy="removeAdditional" v-if="form.customer.id == 13" :customerID="13"></additional>

            </div>

            <div class="vx-col md:w-1/1 w-full mt-5">
              <label for="" class="vs-input--label">{{$i18n.t('InvoiceDescription')}}</label>
              <vs-textarea v-validate="'required'" rows="8" v-model="form.ket_penagihan" name="ket_penagihan" class="w-full" />
              <span class="text-danger">{{ errors.first('step-1.ket_penagihan') }}</span>
            </div>
          </div>
        </form>
      </tab-content>

      <!-- tab 2 content -->
      <tab-content :title="$i18n.t('OriginOfTheLetter')" class="mb-5" icon="feather icon-briefcase" :before-change="validateStep2">
        <form data-vv-scope="step-2">
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full">
              <vs-input v-validate="'required'" :label="$i18n.t('PONumber')" name="no_po_kontrak" v-model="form.no_po_kontrak" class="w-full mt-4" />
              <span class="text-danger">{{ errors.first('step-2.no_po_kontrak') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full">
              <vs-input v-validate="'required'" :label="$i18n.t('DONumber')" name="do_number" v-model="form.do_number" class="w-full mt-4" />
              <span class="text-danger">{{ errors.first('step-2.do_number') }}</span>
            </div>
            <div class="vx-col md:w-1/3 mt-4 w-full">
              <label class="vs-input--label">{{$i18n.t('DODate')}}</label>
              <datepicker format="d/MM/yyyy" :value="form.do_date" @input="form.do_date = $moment($event)"></datepicker>
<!--              <vs-input type="date" v-validate="'required'" :label="$i18n.t('DODate')" name="do_date" v-model="form.do_date" class="w-full mt-4" />-->
              <span class="text-danger">{{ errors.first(`step-1.do_date`) }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full">
              <vs-input v-validate="'required'" :label="$i18n.t('SPPNumber')" name="spp_number" v-model="form.spp_number" class="w-full mt-4" />
              <span class="text-danger">{{ errors.first('step-2.spp_number') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full">
              <vs-input v-validate="'required'" :label="$i18n.t('InvoiceNumber')" name="invoice_number" v-model="form.invoice_number" class="w-full mt-4" />
              <span class="text-danger">{{ errors.first('step-2.invoice_number') }}</span>
            </div>
            <div class="vx-col md:w-1/3 mt-4 w-full">
              <label class="vs-input--label">{{$i18n.t('InvoiceDate')}}</label>
              <datepicker format="d/MM/yyyy" :value="form.invoice_date" @input="form.invoice_date = $moment($event)"></datepicker>
<!--              <vs-input type="date" v-validate="'required'" :label="$i18n.t('InvoiceDate')" name="invoice_date" v-model="form.invoice_date" class="w-full mt-4" />-->
              <span class="text-danger">{{ errors.first(`step-1.invoice_date`) }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full">
              <vs-input v-validate="'required'" :label="$i18n.t('ReceiptNumber')" name="kwitansi_number" v-model="form.kwitansi_number" class="w-full mt-4" />
              <span class="text-danger">{{ errors.first('step-2.kwitansi_number') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full">
              <vs-input v-validate="'required'" :label="$i18n.t('TaxInvoiceNumber')" name="no_faktur_pajak" v-model="form.no_faktur_pajak" class="w-full mt-4" />
              <span class="text-danger">{{ errors.first('step-2.no_faktur_pajak') }}</span>
            </div>
            <div class="vx-col md:w-1/3 mt-4 w-full">
              <label class="vs-input--label">{{$i18n.t('TaxInvoiceDate')}}</label>
              <datepicker format="d/MM/yyyy" :value="form.tgl_faktur_pajak" @input="form.tgl_faktur_pajak = $moment($event)"></datepicker>
<!--              <vs-input type="date" v-validate="'required'" :label="$i18n.t('TaxInvoiceDate')" name="tgl_faktur_pajak" v-model="form.tgl_faktur_pajak" class="w-full mt-4" />-->
              <span class="text-danger">{{ errors.first(`step-1.tgl_faktur_pajak`) }}</span>
            </div>
          </div>
        </form>
      </tab-content>

      <!-- tab 3 content -->
      <tab-content :title="$i18n.t('Attachment')" class="mb-5" icon="feather icon-paperclip" :before-change="validateStep3">
        <form data-vv-scope="step-3">
          <div class="vx-row">

            <div class="vx-col md:w-1/1 w-full">
              <h5>{{ $i18n.t('Invoice') }}</h5>
            </div>

            <div class="vx-col md:w-12/12 w-full mt-4">
              <detail-goods v-on:add="submitGoods" v-on:destroy="removeGoods" :invoiceDate="form.invoice_date" :detailGoods="form.detail_goods"></detail-goods>
            </div>

            <div class="vx-col md:w-1/1 w-full">
              <vs-divider></vs-divider>
            </div>

            <div class="vx-col md:w-6/12 w-full">
              <vs-input :label="$i18n.t('PaymentAccount')" name="rekening_pembayaran" v-model="form.rekening_pembayaran" class="w-full"  v-validate="'required'" />
              <span class="text-danger">{{ errors.first('step-2.rekening_pembayaran') }}</span>
            </div>

          </div>

          <div class="vx-row">
            <div class="vx-col md:w-6/12 w-full mt-4">
              <label for="" class="vs-input--label">{{$i18n.t('Description')}}</label>
              <vs-textarea rows="8" name="keterangan" v-model="form.keterangan" class="w-full" v-validate="'required'" />
              <span class="text-danger">{{ errors.first('step-2.keterangan') }}</span>
            </div>
          </div>

        </form>
      </tab-content>
    </form-wizard>
  </vx-card>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Additional from "@/views/pages/InvoiceCustomer/Additional";
import DetailGoods from "@/views/pages/InvoiceCustomer/DetailGoods";

import axios from 'axios';

// For custom error message
import { Validator } from 'vee-validate';



export default {
  data() {
    return {
      // Options
      customerSelected: null,
      customerOptions: [],

      form: {
        additional: [],
        detail_goods: [],
        matauang_invoice: {
          text: 'IDR',
          value: '3'
        }
      },
      currencies: [
        {
          text: 'USD',
          value: '1'
        },
        {
          text: 'EUR',
          value: '2'
        },
        {
          text: 'IDR',
          value: '3'
        }
      ],
      dict: {
        custom: {
          tanggal_surat: {
            required: this.$i18n.t('LetterDate') + ' is required',
          },
          penerima: {
            required: this.$i18n.t('Receiver') + ' is required',
          },
          nomor_surat: {
            required: this.$i18n.t('LetterNumber') + ' is required',
          },
          nama_perusahaan: {
            required: this.$i18n.t('CompanyName') + ' is required',
          },
          cp: {
            required: this.$i18n.t('ContactPerson') + ' is required',
          },
          telp_fax: {
            required: this.$i18n.t('PhoneFax') + ' is required',
          },
          alamat: {
            required: this.$i18n.t('Adress') + ' is required',
          },
          perihal: {
            required: this.$i18n.t('Subject') + ' is required',
          },
          halaman: {
            required: this.$i18n.t('Page') + ' is required',
          },
          tujuan_surat: {
            required: this.$i18n.t('Purpose') + ' is required',
          },
          lokasi_file: {
            required: this.$i18n.t('FileLocation') + ' is required',
          },
          file: {
            required: this.$i18n.t('File') + ' is required',
          },
        }
      }
    }
  },
  methods: {
    getOptions () {
      axios.get('/customer').then(resp => this.customerOptions = resp.data);
    },
    validateStep1() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-1').then(result => {
          if (result) {
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },
    validateStep2() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-2").then(result => {
          if (result) {
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },
    validateStep3() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-3").then(result => {
          if (result) {
            this.onSubmit();
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },

    formTest () {
      this.form = {
        customer: {id: 13, name: "PT Telekomunikasi Selular"},
        // date_due: "2020-11-09",
        date_issued: "2020-11-09",
        // do_date: "2020-11-09",
        do_number: "A",
        invoice: "100",
        invoice_date: "2020-11-09",
        invoice_number: "A",
        ket_penagihan: "A",
        keterangan: "A",
        kwitansi_number: "A",
        matauang_invoice: this.currencies.find(currency => currency.value == 3),
        no_faktur_pajak: "A",
        no_npwp_customer: "A",
        no_po_kontrak: "A",
        rekening_pembayaran: "A",
        spp_number: "A",
        tanggal: "2020-11-12",
        tgl_faktur_pajak: "2020-11-11",
        po_date: "2020-11-11",
        additional: [],
        detail_goods: [],
      };
    },

    getFields () {
      return [
        'date_due',
        'date_issued',
        'do_date',
        'do_number',
        'invoice',
        'invoice_date',
        'invoice_number',
        'ket_penagihan',
        'keterangan',
        'kwitansi_number',
        'matauang_total_tagihan',
        'nilai_total_tagihan',
        'no_faktur_pajak',
        'no_npwp_customer',
        'no_po_kontrak',
        'pajak',
        'rekening_pembayaran',
        'spp_number',
        'tanggal',
        'tgl_faktur_pajak',
        'po_date',
      ];
    },

    getBody () {
      let fields = this.getFields();

      let body = {};

      Object.keys(this.form).filter(key => fields.includes(key)).map(key => {
        body[key] = this.form[key];
      });

      body.customer = this.form.customer.name;
      body.customer_id = this.form.customer.id;
      body.invoice = this.form.detail_goods.length > 0 ? this.form.detail_goods.map((dg) => dg.total).reduce((total, item) => total += item) : 0;
      body.additional = JSON.stringify(this.form.additional);
      body.detail_goods = JSON.stringify(this.form.detail_goods);
      body.matauang_invoice = this.form.matauang_invoice.value;

      return body;
    },

    submitAdditional (params) {
      this.form.additional.push(params.form);
    },

    removeAdditional (params) {
      this.form.additional.splice(params.index, 1);
    },

    submitGoods (params) {
      this.form.detail_goods.push(params.form);
    },

    removeGoods (params) {
      this.form.detail_goods.splice(params.index, 1);
    },

    onSubmit () {
      let type = 'invoiceCustomer/storeItem', payload = { body: this.getBody() };

      if (this.id) {
        type = 'invoiceCustomer/updateItem';
        payload.body._method = 'PUT';
        payload.id = this.id;
      }

      this.$store.dispatch(type, payload).then((response) => {
        this.$swal({
          title: 'Successful',
          text: response.data.message,
          icon: 'success'
        }).then(() => {
          this.$router.push({name: 'invoice-customer'});
        });
      }).catch(err => {

        // set error
        if (err.data.errors) {
          this.error = err.data.errors;
        }

        // show message error
        this.$vs.notify({
          title: err.data.message,
          text: Object.values(err.data.errors).flat(Infinity).join(' '),
          color:'danger'
        })

      });
    }
  },
  components: {
    FormWizard,
    TabContent,
    Datepicker,
    Additional,
    DetailGoods,
  },
  watch: {
    customerSelected (val) {
      this.form.customer          = val
      this.form.no_npwp_customer  = val.npwp
    },
  },
  created() {
    this.formTest();
    this.getOptions();

    if (this.$route.params.id) {
      this.id = this.$secure.decrypt(this.$route.params.id);
      axios.get('/invoice-customers/' + this.id).then((response) => {
        this.form = response.data.values;

        this.getFields().forEach(field => {
          this.form[field] = response.data.values[field];
        });

        this.form.additional = response.data.values.additional ? JSON.parse(response.data.values.additional) : [];
        this.form.detail_goods = response.data.values.detail_goods ? JSON.parse(response.data.values.detail_goods) : [];
        this.form.customer = {
          id: this.form.customer_id,
          name: this.form.customer,
        };
        
        this.customerOptions.map(item => {
          if(this.form.customer_id == item.id)
            this.customerSelected = item
        });

        this.form.matauang_invoice = this.currencies.find(currency => currency.value == response.data.values.matauang_invoice);
      });
    } else {
      this.$store.commit('invoiceCustomer/CLEAR_ITEM');
    }

    // register custom messages
    Validator.localize('en', this.dict);
  }
}
</script>

<style>
.con-input-upload {
  box-sizing: border-box;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.con-upload .con-img-upload {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>

